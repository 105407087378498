<template>
  <section id="settingsProfile">
    <b-row>
      <b-col>
        <h1>{{ $t('Avatares') }}</h1>
        <h5 class="text-primary">
          {{ $t('avtConf') }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('creAv') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row class="mb-1">
              <b-col style="text-align-last: end;">
                <b-button
                  variant="primary"
                  @click="actionsCRUD('create')"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    size="14"
                  /> {{ $t('crearAvatar') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex justify-content-end mt-2">
                <span>
                  <strong>{{ characters.length }}</strong> {{ $t("contents.elements") }}
                </span>
              </b-col>
            </b-row>
            <b-overlay
              :show="show"
              variant="transparent"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-row
                v-if="characters.length > 0"
                class="m-2"
              >
                <b-col
                  v-for="i in characters"
                  :key="i.node.id"
                  md="1"
                  class="m-1"
                  style="text-align-last: center;"
                >
                  <div class="profile-pic">
                    <b-avatar size="8em">
                      <b-img
                        :src="i.node.image"
                        class="image_avatar"
                        rounded="circle"
                      />
                      <div
                        class="editImage"
                        @click="
                          actionsCRUD(
                            'update',
                            i.node
                          )
                        "
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="edt"
                          size="20"
                        />
                      </div>
                    </b-avatar>
                    <div
                      class="editImage"
                      style="top: 0em; left: 6em;"
                      @click="actionsCRUD('delete', i.node)"
                    >
                      <feather-icon
                        class="del"
                        icon="Trash2Icon"
                        size="18"
                      />
                    </div>
                  </div>
                  <div
                    class="mt-1"
                    style="text-align-last: center;"
                  >
                    <small>{{ i.node.name }}</small>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      centered
      size="lg"
      title="Avatar"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-overlay
          :show="show"
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-form-group
            :label="$t('firstName') + ' *'"
            label-for="name-input"
            :invalid-feedback="$t('required')"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="name"
              :placeholder="$t('firstName')"
              :state="nameState"
              maxlength="150"
              required
            />
          </b-form-group>
          <b-form-group
            :label="$t('dataGeneric.description')"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              class="mb-1"
              :placeholder="$t('dataGeneric.description')"
              rows="2"
              max-rows="4"
            />
          </b-form-group>
          <b-form-group
            :label="$t('asociated')"
            label-for="content-input"
          >
            <b-row>
              <b-col md="9">
                <b-form-tags
                  v-model="contentsTags"
                  :placeholder="$t('addConts')"
                  size="lg"
                  no-outer-focus
                  no-add-on-enter
                  add-button-text
                  add-on-change
                >
                  <template
                    #default="{
                      tags,
                      removeTag
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="tag"
                          variant="primary"
                          @remove="removeTag(tag)"
                        >
                          {{ tag }}
                        </b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  @click.stop.prevent="showModal('cont')"
                >
                  {{ $t('select') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            :label="$t('asociatedCat')"
            label-for="category-input"
          >
            <b-row>
              <b-col md="9">
                <b-form-tags
                  v-model="categoriesTags"
                  :placeholder="$t('addCat')"
                  size="lg"
                  add-on-change
                  no-outer-focus
                >
                  <template
                    #default="{
                      tags,
                      removeTag
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="tag"
                          variant="primary"
                          @remove="removeTag(tag)"
                        >
                          {{ tag }}
                        </b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  @click.stop.prevent="showModal('cat')"
                >
                  {{ $t('select') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Tags"
            label-for="category-input"
          >
            <b-row>
              <b-col md="11">
                <v-select
                  v-model="selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  :placeholder="$t('addTagsC')"
                  @input="modalTags()"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('addTags')
                  "
                  icon="InfoIcon"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-col
            md="8"
            class="cursor-pointer"
            @dragover="event => event.preventDefault()"
            @drop="addFiles($event)"
          >
            <b-form-group
              :label="$t('Image') + ' *'"
              label-for="image"
              :state="imageState"
            >
              <b-avatar
                class="mt-1 dropContainer"
                size="20em"
              >
                <div
                  class="base-image-avatar image_avatar "
                  @click="chooseInput('image')"
                >
                  <span
                    v-if="!imageBol"
                    class="placeholder_photo"
                  >
                    <feather-icon
                      icon="ImageIcon"
                      size="80"
                    />
                    <small>
                      {{ $t("media.dropFilesToCard") }}
                    </small>
                  </span>
                  <b-img
                    v-else
                    id="BImg"
                    rounded="circle"
                    :src="imageSrc == null
                      ? imageShow
                      : imageSrc
                    "
                    class="mb-3 image_avatar"
                    fluid
                    block
                    @error="errorImg"
                  />
                  <b-form-file
                    id="image"
                    v-model="imageFile"
                    accept="image/*"
                    :state="imageState"
                    class="mr-1"
                    style="display: none"
                  />
                </div>
              </b-avatar>
              <small
                v-if="imageState == false"
                class="text-danger"
              >{{ $t('required') }}</small>
            </b-form-group>
          </b-col>
        </b-overlay>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-overlay
          :show="show"
          variant="transparent"
          class="d-flex"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <b-spinner
                type="grow"
                variant="dark"
              />
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <span class="sr-only">{{ $t('wait') }}...</span>
            </div>
          </template>
          <b-button
            variant="danger"
            @click="cancel()"
          >
            {{ $t('dataGeneric.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="ok()"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CATEGORÍA -->
    <b-modal
      ref="CatModal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="[
            { key: 'image', label: $t('contents.category') },
            { key: 'name', label: $t('firstName') },
            { key: 'type', label: $t('type') },
          ]"
          @confirm-selection="getCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal('cat')"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CONTENIDOS -->
    <b-modal
      ref="ConModal"
      size="xl"
      hide-footer
      :title="$t('select')"
    >
      <div class="d-block text-center">
        <content-selector @info="getContent" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal('cont')"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BImg,
  BFormInput,
  BOverlay,
  BAvatar,
  VBTooltip,
  BSpinner,
  BFormFile,
  BFormTags,
  BFormTag,
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'
import { showToast, base64Encode, messageError } from '@/store/functions'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import ContentSelector from '@/views/common/ContentSelector.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BCard,
    BFormTags,
    BFormTag,
    BCardHeader,
    CategorySelector,
    ContentSelector,
    BCardTitle,
    BFormFile,
    BCardBody,
    BAvatar,
    vSelect,
    BSpinner,
    BImg,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: false,
      id: null,
      name: '',
      description: '',
      imageShow: '',
      headers: {},
      imageSrc: null,
      imageBol: false,
      imageFile: null,
      contentsTags: [],
      contents: [],
      categories: [],
      categoriesTags: [],
      nameState: null,
      selected: [],
      idTags: [],
      originalTags: [],
      imageState: null,
      characters: [],
    }
  },
  watch: {
    contentsTags() {
      this.contents = this.contents.filter(item => this.contentsTags.includes(item.tag))
    },
    categoriesTags() {
      this.categories = this.categories.filter(item => this.categoriesTags.includes(item.tag))
    },
    imageFile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageBol = true
              this.imageSrc = value
            })
            .catch(() => {
              this.imageSrc = null
            })
        } else {
          this.imageSrc = null
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = getUserData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getCharacters()
    const badge = document.getElementsByClassName('b-avatar-badge')
    badge.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      element.style = 'font-size: calc(0.80em);  top: 0.5em; right: 0.5em;'
    })
  },
  methods: {
    getCategory(item) {
      const { id, name } = item.node
      this.categoriesTags.push(name)
      this.categories.push({ id, tag: name })
      this.hideModal('cat')
    },
    getContent(item) {
      const id = item[0]
      const tag = item[1]
      this.contentsTags.push(tag)
      this.contents.push({ id, tag })
      this.hideModal('cont')
    },
    showModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.show()
          break
        case 'cont':
          this.$refs.ConModal.show()
          break
        default:

          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.hide()
          break
        case 'cont':
          this.$refs.ConModal.hide()
          break
        default:
          break
      }
    },
    modalTags() {
      const userData = getUserData()
      const { headers } = this

      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
          {
            extendedTags(name:"${this.selected[this.selected.length - 1]}") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `,
          }, { headers })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.extendedTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })
            if (crear) {
              /* AQUÍ CREA EL TAG */
              axios.post('', {
                query: `
              mutation{
                createExtendedTags(input:{name:"${this.selected[this.selected.length - 1]
}",client:"${userData.profile.client.id}"}){
                    extendedTag{
                        id
                        name
                    }
                }
            }
            `,
              }, { headers })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createExtendedTags.extendedTag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteFile.push(type)
            switch (type) {
              case 'image':
                this.imageBol = false
                this.imageShow = ''
                this.imageSrc = null

                break

              default:
                break
            }
          }
        })
        .catch(() => { })
    },
    getCharacters() {
      const userData = getUserData()
      const { headers } = this
      axios
        .post('', {
          query: `{
            allCharacter(client:"${userData.profile.client.id}", orderBy:"created_at") {
              edges {
                node {
                  id
                  name
                  description
                  image                  
                  tags {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  contents {
                    edges {
                      node {
                        id
                        name
                        
                      }
                    }
                  }
                  categories {
                    edges {
                      node {
                        id
                        name
                        
                      }
                    }
                  }
                }
              }
            }
          }`,
        }, { headers })
        .then(result => {
          messageError(result, this)
          this.characters = result.data.data.allCharacter.edges
          this.show = false
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.id != null) this.updateCharacter()
      else { this.createCharacter() }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      let validate = true
      if (this.name.trim().length === 0) {
        validate = valid
        this.nameState = valid
      } else { this.nameState = true }
      if (!this.imageBol) {
        validate = valid
        this.imageState = valid
      } else { this.imageState = true }

      return validate
    },
    addFiles(event) {
      event.dataTransfer.files.forEach(element => {
        this.imageFile = element
      })

      event.preventDefault()
    },
    createCharacter() {
      if (!this.checkFormValidity()) {
        return
      }
      this.show = true
      const { headers } = this
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
          mutation($name: String!,$description:String,$client:ID!,$tags: [ID]
          ,$contents: [ID],$categories: [ID]){
            createCharacter(input:{name:$name,
              description:$description,
              client:$client,
              tags:$tags,
              contents:$contents,
              categories:$categories
              }){
              character {
                id
                name
                
              }
            }
          }`
      const variables = {
        name: this.name,
        description: this.description,
        client: userData.profile.client.id,
        tags: [...this.idTags],
        contents: this.formatObject(this.contents),
        categories: this.formatObject(this.categories),
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('image', this.imageFile)
      axios
        .post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
          showToast(this.$t('code.updateData'), 1, this)

          this.getCharacters()
        })
        .catch(err => {
          showToast(this.$t('code.updateDataError'), 2, this)
          // eslint-disable-next-line
          console.log(err)
        })
    },
    formatObject(object) {
      const array = []

      object.forEach(element => {
        array.push(element.id)
      })
      return array
    },
    updateCharacter() {
      if (!this.checkFormValidity()) {
        return
      }
      const { headers } = this
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
          mutation($name: String!,$description:String,$tags: [ID],
          $contents: [ID],$categories: [ID]){
            updateCharacter(id:"${this.id}",input:{
              name:$name,
              description:$description,
              tags:$tags,
              contents:$contents,
              categories:$categories,
              }){
              character {
                id
                name
                
              }
            }
          }`
      const variables = {
        name: this.name,
        description: this.description,
        tags: [...this.idTags],
        contents: this.formatObject(this.contents),
        categories: this.formatObject(this.categories),
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      if (this.imageFile) { data.append('image', this.imageFile) }

      axios
        .post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
          showToast(this.$t('code.updateData'), 1, this)
          this.getCharacters()
        })
        .catch(err => {
          showToast(this.$t('code.updateDataError'), 2, this)
          // eslint-disable-next-line
          console.log(err)
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    handleSubmit(bvModalEvt) {
      this.handleOk(bvModalEvt)
    },
    actionsCRUD(value, data = []) {
      const {
        id, name, image, description, tags, contents, categories,
      } = data
      const idTags = []
      const nameTags = []
      const idCategories = []
      const categoriesTags = []
      const idContents = []
      const contentsTags = []

      switch (value) {
        case 'update':

          this.id = id
          this.name = name

          tags.edges.forEach(element => {
            idTags.push(element.node.id)
            nameTags.push(element.node.name)
          })
          this.imageFile = null

          categories.edges.forEach(element => {
            idCategories.push({ id: element.node.id, tag: element.node.name })
            categoriesTags.push(element.node.name)
          })

          contents.edges.forEach(element => {
            idContents.push({ id: element.node.id, tag: element.node.name })
            contentsTags.push(element.node.name)
          })
          this.idTags = idTags
          this.selected = nameTags
          this.contents = idContents
          this.categories = idCategories
          this.categoriesTags = categoriesTags
          this.contentsTags = contentsTags
          this.imageBol = false
          this.imageShow = ''
          this.imageSrc = null
          this.imageBol = image !== ''
          this.imageShow = this.imageBol ? image : ''
          this.description = description
          this.$refs.modal.show()

          break
        case 'create':
          this.imageBol = false
          this.imageSrc = null
          this.imageFile = null
          this.imageShow = ''
          this.idTags = []
          this.selected = []
          this.contents = []
          this.categories = []
          this.categoriesTags = []
          this.contentsTags = []
          this.$refs.modal.show()
          break
        case 'delete':
          this.show = true
          this.$swal({
            title: this.$t('code.title'),
            text: this.$t('sureRemAv'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('code.confirm'),
            cancelButtonText: this.$t('dataGeneric.cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              axios
                .post('', {
                  query: `
                mutation{
                    deleteCharacter(id:"${id}"){
                        found
                        deletedId
                    }
                }
                `,
                })
                .then(res => {
                  messageError(res, this)
                  showToast(this.$t('code.updateData'), 1, this)
                  this.getCharacters()
                })
                .catch(() => {
                  showToast(this.$t('code.updateDataError'), 2, this)
                  this.show = false
                })
            } else {
              this.show = false
            }
          }).catch(err => {
            // eslint-disable-next-line
            console.log(err)
          })
          break

        default:
          break
      }
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    resetModal() {
      this.name = ''
      this.id = null
      this.description = ''
      this.nameState = null
      this.imageState = null
      this.show = false
    },

  },
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body h4 {
  font-size: 1.286rem !important;
}

.col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.fly-image-input {
  max-width: 100%;
}

.base-image-avatar {
  display: block;
  background-size: contain;
  background-position: center center;
}

.base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.div {
  display: inline-block;
}

.inputColor {
  visibility: hidden;
}

.placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;

  flex-direction: column;

}

.placeholder_photo:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.cBox {
  align-items: center;
}

.custom-control-label {
  font-size: 15px;
}

.scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.contain {
  object-fit: contain;
  cursor: pointer;
}

.image_avatar {
  /* object-fit: scale-down; */
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}

.editImage {
  position: absolute;
  display: none;
}

.profile-pic:hover .editImage {
  display: block;
}

.profile-pic:hover .image_avatar {
  opacity: 30%;
}

.profile-pic {
  position: relative;
  display: inline-block;
}

.edt:hover {
  color: rgb(0, 150, 13);
  cursor: pointer;
}

.del:hover {
  color: #a60303;
  cursor: pointer;
}

.dropContainer {
  border: 3px dashed rgb(154, 154, 154);
}
</style>
